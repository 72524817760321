import "./style.css";

const Index = () => {
  return (
    <footer>
      Mint through Metamask Wallet or Open in Trust Wallet Browser
    </footer>
  );
};

export default Index;
